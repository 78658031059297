<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" @submit.native.prevent>
        <el-form-item label="分类名称" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>

    <div class="curTableWrap" ref="main">
      <el-table
        v-if="refreshTable"
        :data="table.data"
        :height="height"
        border
        size="mini"
        ref="refTable"
        :default-expand-all="isExpandAll"
        header-align="center"
        align="center"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :header-cell-style="{background:'#f5f5f5'}"
        row-key="id"
        style="width: 100%">
        <el-table-column type="expand" style="padding:0 !important;" width="60"  >
          <template slot="header">
            <el-tooltip
              effect="dark"
              :content="isExpandAll ? `收起` : `展开`"
              placement="bottom"
            >
              <i class="el-icon-menu" color="#2cb167" style="color:#2cb167" @click="toggleExpand" ></i>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <div class="demo-table-expand" v-if="row.child.length > 0">
              <div class="specwrap" v-for="(item,index) in row.child" :key="index">
                <div class="specitem" style="display:flex;justify-content: space-between;">
                  <div style="width:140px;!important;border-right:solid 1px #eee;"></div>
                  <div style="width:160px;padding-right:3px;border-right:solid 1px #eee;">{{item.name}}</div>
                  <!-- <div style="width:80px;padding-right:3px;border-right:solid 1px #eee;color:#a8a8a8;">{{item.sort}}</div> -->
                  <div class="item" style="padding:0 1px;text-align:left;">
                    <span style="width:80px;text-align:center;display:inline-block;padding-right:3px;#eee;color:#a8a8a8;">{{item.sort}}</span>
                    <span class="childItem" style="color:#e6a23c;" @click="selGoodsChild(row,item)">{{item.goods_num}}</span>
                  </div>
                  <div style="width:200px !important;border-right:solid 1px #eee;">{{item.create_time}}</div>
                  <div style="width:200px;">
                    <span class="textBtn" style="margin-right:10px;" @click="handleChild(item,2)">编辑</span>
                    <span class="textBtn" @click="removeChild(item)" v-if="item.goods_num == 0">删除</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="ID"
          prop="id"
          width="80">
        </el-table-column>
        <el-table-column
          label="下单分类名称"
          prop="name"
          width="160px">
          <template slot-scope="{row}">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <span>{{row.name}}</span>
              <el-image
                v-if="row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
          align="center"
          width="80px">
        </el-table-column>
        <el-table-column
          label="商品数量"
          prop="goods_num"
          align="center"
          width="140px">
          <template slot-scope="{row}">
            <el-link style="padding: 0 10px;"  type="primary" @click="selGoods(row)">{{row.goods_num}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="匹配商品分类"
          prop="cate"
          align="center">
          <template slot-scope="{row}">
            <div v-if="row.cate.length > 0">
            <span v-for="(v,i) in row.cate" :key="i">
              <span>{{v.name}}</span>
              <span v-if="i < row.cate.length - 1">/</span>
            </span>
          </div>
          <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          align="center"
          width="200">
        </el-table-column>
        <el-table-column
          label="操作"
          prop="active"
          align="center"
          width="200">
          <template slot-scope="{row}">
            <el-button type="text" @click="showEdit(row)">编辑</el-button>
            <el-button type="text" @click="handleChild(row,1)">添加子类</el-button>
            <el-button type="text" @click="remove(row)" v-if="row.goods_num == 0 && is_sys == 1">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加子类 -->
    <el-dialog :title="`${type == 1 ? '添加' : '编辑'}子类`" width="400px" :visible.sync="dialogVisibleForm" :before-close="handleClose" center>
      <el-form
        class="staff-from"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="子类名称" prop="name">
          <el-input v-model="form.data.name" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model.trim="form.data.sort"
            placeholder="数字越小越靠前"
            clearable
            @input="form.data.sort=form.data.sort.replace(/^(0+)|[^\d]+/g, '')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="form.loading" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 绑定供货商品 -->
    <SelGoods
      :dialogTitle="dialogTitle"
      :childText="childText"
      ref="selGoods"
      :getDataApi="getDataApi"
      :setDataApi="setDataApi"
      :showBind="showBind"
      :sourceType="sourceType"
      @refresh="getTable">
    </SelGoods>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import SelGoods from '@/components/SelGoods.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'orderCateIndex',
  components: {
    Edit,
    SelGoods,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      getDataApi: '/admin/mini_cate/goods',
      setDataApi: '/admin/mini_cate/bind',
      showBind: false,
      height:0,
      isExpandAll: true,
      refreshTable: true,

      sourceType: 4, // 下单分类
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "下单分类名称", hidden: false },
        { name: "sort", label: "排序", hidden: false},
        { name: "goods_num", label: "商品数量", hidden: false},
        { name: "cate", label: "匹配商品分类", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      dialogTitle: '', //分类名称
      childText: '', //分类子级名称
      dialogVisibleForm: false,
      form: {
        loading: false,
        data: {
          pid: '', // 父id
          pName: '', // 父名称
          id: '',  // 子id
          name: '', // 子名称
          sort: '', // 子排序
        },
        rules: {
          name: [{ required: true, message:'请输入名称', trigger: 'blur'}],
          sort: [{ required: true, message:'请输入排序', trigger: 'blur'}],
        }
      },
      type: 1,
    }
  },
  created() {
    this.getTable();
  },
  mounted() {
    this.height = this.$refs.main.offsetHeight || 500
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/mini_cate/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          // this.table.data = res.data.list.map(obj => ({ ...obj })).reduce((acc, obj) => {
          //   if (obj.child.length == 0) {
          //     delete obj.child;
          //     acc.push(obj);
          //   } else {
          //     acc.push(obj);
          //   }
          //   return acc;
          // }, []);
          console.log(this.table.data,"123")
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    //负者供货商品
    selGoods(row) {
      this.dialogTitle = `下单分类-${row.name}`;
      let dom = this.$refs.selGoods;
      this.childText = '';
      dom.toggle(true);
      dom.getDetail(row);  // 下单分类
      dom = null;
    },
    selGoodsChild(row,item) {
      this.dialogTitle = `下单分类-${row.name}`;
      this.childText = item.name; // 二级分类名称
      let dom = this.$refs.selGoods;
      dom.toggle(true);
      dom.getDetail(item); // 传下单分类子级
      dom = null;
    },
    // 删除
    remove(row) {
      if(row.goods_num > 0) {
        this.$message.warning('该分类中有商品，请先在商品列表修改掉该分类再操作')
      } else{
        this.setConfirm(
          `确认删除操作？`,
          {  id: row.id },
          "/admin/mini_cate/delete"
        );
      }
    },
    // 展开、收起规格
    toggleExpand() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      })
    },
    handleClose() {
      this.dialogVisibleForm = false;
      this.type = 1;
      this.form.data.id = '';
      this.form.data.name = '';
      this.form.data.sort = '';
      this.form.data.pid = '';
      this.form.data.pName = '';
    },
    handleChild(row, type) {
      this.dialogVisibleForm = true;
      this.type = type; //子类： 1添加，2编辑
      if(type == 1) { // 添加子类
        this.form.data.pid = row.id; // 父id
        this.form.data.pName = row.name; // 父名称
      } else  { // 编辑子类
        this.form.data.id = row.id; // 子id
        this.form.data.name = row.name; // 子名称
        this.form.data.sort = row.sort; // 子类排序
      }
    },
    onSubmit() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params ={}
          let apiUrl = ''
          if(this.type == 1) { // 添加子类
            _params = {
              pid: this.form.data.pid,
              name: this.form.data.name,
              sort: this.form.data.sort,
            }
            apiUrl = '/admin/mini_cate/addChild';
          } else  { // 编辑子类
            _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              sort: this.form.data.sort,
            }
            apiUrl = '/admin/mini_cate/editChild';
          }
          this.form.loading = true;
          this.$http.post(apiUrl,_params).then(res => {
            if(res.code == 1) {
              this.dialogVisibleForm = false;
              this.form.data.name = ''
              this.$message.success("操作成功")
              this.getTable();
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
    // 删除子类
    removeChild(item) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: item.id },
        "/admin/mini_cate/delChild"
      );
    }
    
  }
}
</script>
<style scoped>
::v-deep .el-table__expanded-cell{
  padding: 0 !important;
}
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.curTableWrap::-webkit-scrollbar {
  width:0px;
}
.curTableWrap::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0px;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
.specitem {
  border-bottom: solid 1px #eee;
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #f8fffb;
}
.specitem .item {
  border-right: solid 1px #eee;
  text-align: center;
  flex: 1;
}
.specitem .item:last-child{
  border-right: none;
}
.specitem .textBtn {
  color: #e6a23c;
  cursor: pointer;
}
.specitem .textBtn:hover {
  text-decoration: underline;
}
.childItem {
  color: #e6a23c;
  cursor: pointer;
  width: 134px;
  display: inline-block;
  text-align: center;
}
.childItem:hover {
  text-decoration: underline;
}
</style>
